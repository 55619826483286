<template>
  <v-container
    fluid
  >

    <lessonViewer
      ref="lessonViewer"
      :courseId="courseId"
      :lesson="selectedLesson"
      :moderator="moderator"
    >
    </lessonViewer>

  </v-container>
</template>

<script>
  import errorUtils from '@/utils/errorUtils'
  import { RepositoryFactory } from '@/repositories/RepositoryFactory'
  import { eventBus } from "@/main"

  const CourseRepository = RepositoryFactory.get('course');

  export default {
    name: 'Course',

    components: {
      lessonViewer: () => import("@/components/LessonViewer"),
    },

    data: () => ({
      lessonVisible: false,
      course: {},      
      courseItems: [],
      selectedLesson: {},
      courseId: 0,
      moderator: false,
    }),

    created() {
      eventBus.$on("select-lesson", (data) => {
          this.selectLesson(data);
      });
    },

    destroyed() {
      this.$store.state.exploreCourse = false;
      eventBus.$off("select-lesson");
    },

    mounted() {
      this.loadCourse();
    },

    methods: {

      backToMenu() {
        this.lessonVisible = false;
      },

      async loadCourse() {
        var i;
        var j;
        var lessonItem;
        var headings;
        var headingNumber;
        var widget;
        var headingItem;

        const { data } = await CourseRepository.getCourse(this.$route.params.courseid);
        if (data.success) {    
          this.course = data.data.course;
          this.moderator = this.course.moderator
          this.courseId = parseInt(this.$route.params.courseid);

          // build course tree items
         
          this.courseItems = [];
          i = 0;
          while (i < this.course.lessons.length) {
            lessonItem = this.course.lessons[i];
            lessonItem.isLesson = true;
            lessonItem.lessonIndex = i;

            // lesson headings

            headingNumber = 1;
            headings = [];
            j = 0;
            while (j < lessonItem.widgets.length) {
              widget = lessonItem.widgets[j];        
              if (widget.widgettypeid==10) {               
                headingItem = {};
                headingItem.title = headingNumber + '. ' + widget.instance.text;
                headingItem.isLesson = false;
                headingItem.lessonIndex = i;
                headingItem.widgetIndex = j;
                headingNumber++;
                headings.push(headingItem);
              }
              j++;
            }
            if (headings.length > 0) {
              lessonItem.children = headings;
            }

            this.courseItems.push(lessonItem);
            i++;
          }

          // select lesson

          var lessonIndex = 0;
          if (Object.prototype.hasOwnProperty.call(this.$store.state.lessonSelected, 'c' + this.$route.params.courseid)) {
            lessonIndex = this.$store.state.lessonSelected['c' + this.$route.params.courseid];
          }
          if (lessonIndex >= this.course.lessons.length) {               
            lessonIndex = 0;
          }
          if (lessonIndex < this.course.lessons.length) {          
            this.selectedLesson = this.course.lessons[lessonIndex];
          }
        
          //var tabIndex = 0;
          //if (Object.prototype.hasOwnProperty.call(this.$store.state.lessonSelected, 'ct' + this.$route.params.courseid)) {
          //  tabIndex = this.$store.state.lessonSelected['ct' + this.$route.params.courseid];
          //}          

          this.$store.state.lessonIndex = lessonIndex;
          //this.$store.state.tabIndex = tabIndex;
          this.$store.state.courseId = this.$route.params.courseid;
          this.$store.state.courseModerator = this.course.moderator;
          this.$store.state.lessonItems = this.courseItems;         
          this.$store.state.exploreCourse = true;
          
        } else {          
          errorUtils.showError(data.message);
        }
      },

      selectLesson(lessonIndex) {
        this.selectedLesson = this.course.lessons[lessonIndex];
        //this.$store.state.tabIndex = 0;
        this.$store.commit('selectLesson', {courseId: this.$route.params.courseid, lessonIndex: lessonIndex } );            
      },

      completedStyle(item) {
        if (item.completed) {
          return 'success';
        }
        return '';        
      },

      toggleLessonCompleted(lesson) {
        lesson.completed = !lesson.completed;
        if (lesson.completed) {
          CourseRepository.markLessonComplete(lesson.lessonid);
        } else {
          CourseRepository.clearLessonComplete(lesson.lessonid);
        }
      },

    }

  }
</script>
